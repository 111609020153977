@import "src/styles/mixins";

.order {
  &__plan {
    padding: 16px 0;

    border-top: 1px solid var(--grey-ultralight);
    border-bottom: 1px solid var(--grey-ultralight);

    @include flex($direction: column);
    gap: 6px;
  }

  &__total {
    padding: 16px 0;

    @include flex($direction: column);
    gap: 4px;

    border-bottom: 1px solid var(--grey-ultralight);
  }

  &__disclaimer {
    padding: 16px 12px;
    margin-top: 20px;

    color: var(--black-50);
    background: var(--grey-light);

    border-radius: 12px;
    gap: 8px;

    @include flex($direction: column);
  }
}

.line {
  @include flex($align: center, $justify: space-between);
  gap: 24px;
}

.text {
  color: var(--black-50);

  font-weight: 400 !important;

  &--red {
    color: var(--red-color);

    font-weight: 600 !important
  }
}

.link {
  position: relative;
  z-index: 10;

  color: var(--black-100);

  text-decoration: underline;
}
