@import "src/styles/fonts";

.typography {
  &.headline_large {
    font-weight: 700;
    font-size: 34px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.3px;
  }

  &.headline_large_ital {
    font-weight: 400;
    font-size: 34px;
    font-family: $secondary-font-family;
    font-style: italic;
    line-height: normal;
    letter-spacing: -0.3px;
  }

  &.headline_medium {
    font-weight: 700;
    font-size: 28px;
    font-style: normal;
    line-height: normal;
  }

  &.headline_medium_ital {
    font-weight: 400;
    font-size: 28px;
    font-family: $secondary-font-family;
    font-style: italic;
    line-height: normal;
  }

  &.headline_small {
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    line-height: normal;
  }

  &.title_large {
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
  }

  &.title_medium {
    font-weight: 700;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }

  &.title_small {
    font-weight: 700;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 5px;

    text-transform: uppercase;
  }

  &.display_medium {
    font-weight: 700;
    font-size: 10px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: 1px;
  }

  &.display_small {
    font-weight: 700;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
  }

  &.label_extra_large {
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
  }

  &.label_large {
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }

  &.label_medium {
    font-weight: 500;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
  }

  &.label_small {
    font-weight: 500;
    font-size: 10px;
    font-style: normal;
    line-height: normal;
  }

  &.body_large {
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
  }

  &.body_medium {
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }

  &.body_small {
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
  }
}
