@import "src/styles/mixins";

.banner {
  padding: 16px;

  background: var(--white-100);

  border: 1px solid var(--grey-ultralight);
  border-radius: 24px;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 12%);

  &__top {
    @include flex($align: center);
    gap: 12px;

    margin-bottom: 12px;
  }

  &__logo {
    flex-shrink: 0;
  }

  &__text {
    color: var(--black-70);

    letter-spacing: -0.3px;
  }

  &__button {
    @include flex($align: center, $justify: center);
    width: 100%;

    padding: 10px;

    color: var(--white-100);
    background: var(--black-100);

    border-radius: 32px;

    font-weight: 700;
    font-size: 20px;

    text-align: center;
    gap: 10px;
  }
}
