.slider_container {
  position: relative;
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #1b1a1f;
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #1b1a1f;
    cursor: pointer;
    box-shadow: none;
  }

  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #1b1a1f;
    cursor: pointer;
    box-shadow: none;
  }

  &:hover::-webkit-slider-thumb {
    box-shadow: none;
  }

  &:active::-webkit-slider-thumb {
    box-shadow: none;
  }
}
