.logo {
  display: block;

  &.small {
    width: 40px;
  }

  &.medium {
    width: 60px;
  }
}
