@import "src/styles/mixins";

.header {
  &__logo {
    display: block;
    @include square(44px);
    border-radius: 8px;
    margin: 0 auto 16px;
  }

  &__title {
    text-align: center;
    font-size: 22px !important;
  }

  &__close {
    position: absolute;
    @include flex($align: center, $justify: center);
    top: 8px;
    right: 16px;
    z-index: 3;
    width: 36px;
    height: 36px;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill-opacity: 1;
      }
    }
  }
}
