.root {
  margin: 0 auto 30px;

  position: relative;
  z-index: 2;

  color: white;
}

.bar {
  height: 6px;

  margin: 0 0 30px;

  background: var(--white-100);

  border-radius: 8px;

  transition: width linear 100ms;
}

.text {
  color: var(--white-100);

  font-weight: 700;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 5px;

  text-align: center;
  text-transform: uppercase;
}
