@import "src/styles/mixins";
@import "src/styles/fonts";

.form {
  &__fields {
    @include flex($direction: column);
    gap: 12px;

    margin-bottom: 20px;
  }

  &__label {
    margin-bottom: 2px;
  }

  &__input {
    padding: 12px;

    background-color: var(--blue-10) !important;

    border: 1px solid var(--black-5);
    border-radius: 8px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: var(--blue-10) !important;

      box-shadow: 0 0 0 1000px var(--blue-10) inset !important;
    }
  }

  &__button {
    height: 55px;

    color: var(--black-100);
    background: var(--yellow-accent);

    border: none;
    border-radius: 12px;

    transition: background 300ms, color 300ms, opacity 300ms;

    &:disabled {
      opacity: 0.3;
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: -50px;

  transform: translate(0, -50%);
}
