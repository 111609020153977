.title, .bold {
  font-size: 34px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

.title {
  padding: 0;
  margin: 0;

  font-weight: 700;
}

.secondPartTitle {
  font-weight: 400;
  font-family: 'Brygada 1918', sans-serif;
  font-style: italic;
}

.isSmall {
  font-size: 24px;
  letter-spacing: 0;
}
