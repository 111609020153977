.systems {
  margin: 0 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: var(--grey-dark);

  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

.cards {
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;

  list-style: none;
  gap: 1px;
}

.card {
  width: 23px;
  height: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0.344px solid #717070;
  border-radius: 2.754px;
}

.image {
  display: block;
}

.visa {
  width: 18.587px;
  height: 7.174px;
}

.mastercard {
  width: 13px;
  height: 10px;
}

.maestro {
  width: 21.341px;
  height: 13.696px;
}

.discover {
  width: 21.341px;
  height: 11.087px;
}
