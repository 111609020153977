.image {
  &__wrapper {
    height: 100%;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__main {
    width: 100%;
    height: 100%;

    position: absolute;
    z-index: 2;

    display: block;
  }

  &__preview {
    width: 100%;
    height: 100%;

    display: block;

    filter: blur(4px);

    &.is_hidden {
      visibility: hidden;
    }
  }
}
