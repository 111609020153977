@import "src/styles/mixins";

.backdrop {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  &.is_open {
    animation: fadeIn 300ms ease-in-out forwards;
  }


  &.full {
    @include flex($direction: column);

    .modal {
      @include flex($direction: column);
      flex: 1;
    }
  }

  &.top {
    @include flex($align: flex-start, $justify: center);
  }

  &.center {
    @include flex($align: center, $justify: center);
  }

  &.bottom {
    @include flex($align: flex-end, $justify: center);
  }
}

.modal {
  max-width: 100%;
  max-height: 100%;

  overflow-y: auto;

  position: relative;

  transform: translate3d(0,0,0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modalEnter {
  opacity: 0;

  transition: opacity 300ms ease-in-out;

}

.modalEnterActive {
  opacity: 1;

  transition: opacity 300ms ease-in-out;
}

.modalExit {
  opacity: 1;

  transition: opacity 300ms ease-in-out;

}

.modalExitActive {
  opacity: 0;

  transition: opacity 300ms ease-in-out;
}
