.info {
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.bold {
  font-weight: 700;
}

.link {
  color: var(--black-100);

  text-decoration: underline;
}
