@import "src/styles/fonts";
@import "src/styles/mixins";

.container {
  width: 100%;
  max-width: 360px;

  &.top-center {
    padding: 4px;

    top: var(--toastify-toast-top);
    left: 50%;

    transform: translateX(-50%);
  }
}

.toast {
  padding: 12px;

  border-radius: 10px;

  font-size: 13px;
  font-family: $primary-font-family;
  line-height: normal;

  &.error {
    color: var(--red-color);
    background: var(--red-20);

    border: 2px solid var(--red-color);
  }
}

.progress {
  &.error {
    background: var(--red-color);
  }
}

.close {
  &__button {
    flex-shrink: 0;

    @include square(24px);
    @include flex($align: center, $justify: center);
  }

  &__icon {
    path {
      fill: var(--red-color);
    }
  }
}
