@import "src/styles/mixins";

.checkout {
  width: 100%;
  height: 100%;

  position: fixed;
  inset: 0;

  z-index: -5;

  transition: z-index 500ms ease-in-out;
  pointer-events: none;

  &.slide-up {
    .checkout__backdrop {
      width: 100%;
      height: 100%;

      position: absolute;

      opacity: 0;

      background: rgb(17 17 19 / 75%);

      transition: opacity 400ms ease-in-out;
      backdrop-filter: blur(6px);
    }

    .checkout__content {
      width: 100%;
      height: 100%;

      transform: translateY(100%);

      transition: transform 500ms ease-in-out;
    }
  }


  &.fade-in {
    .checkout__backdrop {
      display: none;
    }

    .checkout__content {
      width: 100%;
      height: 100%;

      opacity: 0;

      transition: opacity 300ms ease-in-out;
    }
  }
}

.checkout.is_open {
  pointer-events: visible;

  z-index: 100;

  &.slide-up {
    .checkout__backdrop {
      opacity: 1;
    }

    .checkout__content {
      transform: translateY(0);
    }
  }

  &.fade-in {
    .checkout__content {
      opacity: 1;
    }
  }
}

.backdrop {
  background: var(--black-50);
  backdrop-filter: blur(6.5px);
}
