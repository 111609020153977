@import "src/styles/mixins";
@import "src/styles/fonts";

.container {
  @include flex($direction: column, $align: center);
  max-width: 375px;

  margin: 20px;
}

.content {
  padding: 20px;

  background: var(--white-100);

  border-radius: 18px;
}

.header {
  &__image {
    width: 100%;
    max-width: 192px;

    display: block;

    transform: translateY(20px);
  }
}

.main {
  &__solution {
    font-family: $tertiary-font-family;

    text-align: center;

    &_title {
      margin-bottom: 16px;

      font-size: 25px !important;
    }

    &_total {
      margin-bottom: 12px;
    }

    &_text {
      margin-bottom: 24px;
    }

    &_buttons {
      @include flex($direction: column);
      gap: 4px;

      margin-bottom: 16px;
    }
  }

  &__support {
    margin-bottom: 16px;

    color: var(--black-50);

    text-align: center;
  }

  &__divider {
    @include flex($align: center);
    width: 100%;
    height: 14px;

    margin-bottom: 16px;

    position: relative;

    &_text {
      padding: 0 10px;

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;

      color: var(--black-50);
      background: var(--white-100);

      text-transform: uppercase;

      transform: translate(-50%, -50%);
    }

    &::before {
      width: 100%;
      height: 1px;

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;

      background: var(--line-color);

      transform: translate(-50%, -50%);
      content: '';
    }
  }

  &__link {
    font-weight: 700;

    text-decoration: underline;
  }
}

.footer {
  padding: 8px 8px 8px 16px;

  display: grid;
  grid-template-columns: 1fr 1.25fr;

  background: rgb(130 105 229 / 10%);

  border-radius: 10px;
  gap: 8px;

  &__text {
    text-transform: uppercase;
  }

  &__store {
    width: 144px;

    margin: auto;

    display: block;
    cursor: pointer;
  }
}
