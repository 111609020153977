.wallet_button {
  margin-bottom: 20px;
}

.base_number {
  padding: 15px 12px;

  grid-area: number;

  color: #1B1A1F;

  border: 1px solid #D8D8D8;
  border-radius: 6px;

  font-weight: 500;
  font-size: 14px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;

  &::placeholder {
    opacity: 0.5;
  }
}

.base_date {
  padding: 15px 12px;

  grid-area: date;

  color: #1B1A1F;

  border: 1px solid #D8D8D8;
  border-radius: 6px;

  font-weight: 500;
  font-size: 14px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;

  &::placeholder {
    opacity: 0.5;
  }
}

.base_code {
  padding: 15px 12px;

  grid-area: code;

  color: #1B1A1F;

  border: 1px solid #D8D8D8;
  border-radius: 6px;

  font-weight: 500;
  font-size: 14px;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  line-height: normal;

  &::placeholder {
    opacity: 0.5;
  }
}

.custom_form {
  padding: 20px 0;

  display: grid;
  grid-auto-columns: 1fr 1fr;
  grid-gap: 10px;
  grid-template-areas: 'number number'
  'date code';
}


.info {
  color: var(--grey-dark);

  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: normal;

  text-align: center;
}

.total {
  margin: 0 0 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--grey-dark);

  font-weight: 700;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

.submit_button {
  margin: 0 0 22px;
}

.loading_text {
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  left: -50px;

  transform: translate(0, -50%);
}
