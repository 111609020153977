.input {
  padding: 16px;

  color: var(--input-color);
  background: var(--input-bg-color);

  border: 1px solid var(--input-bg-color);
  border-radius: 8px;

  font-weight: 700;

  font-size: 14px;
  font-style: normal;
  line-height: normal;

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &::placeholder {
    opacity: 0.3;
  }
}

.error {
  color: var(--red-color) !important;

  border: 1px solid var(--red-color) !important;
}
