@import 'src/styles/mixins';

.button {
  width: 100%;

  padding: 10px;

  flex: 1;

  border-radius: 12px;

  box-shadow: 0 0 0 2px inset var(--grey-ultralight);

  transition: box-shadow 250ms;

  cursor: pointer;

  @include flex($direction: column, $justify: center, $align: center);

  &.is_active {
    box-shadow: 0 0 0 2px inset var(--yellow-accent);

    .button__text {
      filter: grayscale(0);
      opacity: 1;
    }

    .button__icon {
      filter: grayscale(0);
      opacity: 1;
    }
  }


  &__text {
    filter: grayscale(1);
    opacity: 0.5;

    color: var(--black-100);

    font-weight: 700;
    font-size: 12px;

    transition: filter 250ms, opacity 250ms;
  }

  &__icon {
    filter: grayscale(1);
    opacity: 0.5;

    transition: filter 250ms, opacity 250ms;
  }
}
