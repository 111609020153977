@import 'src/styles/mixins';
@import 'src/styles/media-queries';

.container {
  max-width: 620px;
  max-height: calc(100vh - 100px);
  margin: 0 auto;
}

.content {
  overflow-y: auto;
  border-radius: 12px 12px 0 0;
  background: var(--white-100);
}

.header {
  position: sticky;
  z-index: 5;
  top: 0;
  padding: 20px 10px 8px;
  background: var(--white-100);

  &__title {
    text-align: center;
  }

  &__close {
    position: absolute;
    @include flex($align: center, $justify: center);
    top: 16px;
    right: 16px;
    z-index: 3;
    width: 30px;
    height: 30px;

    svg path {
      fill-opacity: 1;
    }
  }
}

.form {
  padding: 8px 20px 20px;
  position: relative;
}

.buttons {
  @include flex($justify: space-between);
  gap: 5px;
}

.order {
  position: absolute;
  top: 102px;
  left: 20px;
  right: 20px;
}

.slider {
  /* stylelint-disable */
  display: flex !important;
  /* stylelint-enable */
  flex-direction: column-reverse;
  //padding: 0 12px;

  @include medium() {
    padding: unset;
  }

  &.is_disabled {
    pointer-events: none !important;
  }
}

.slide {
  width: 100%;
  /* stylelint-disable */
  height: auto !important;


  &__pay_button {
    margin-bottom: 15px;
  }
}
