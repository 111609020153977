.ldsRing {
  width: 40px;
  height: 40px;

  position: relative;

  display: inline-block;
}

.ldsRing div {
  box-sizing: border-box;
  width: 32px;
  height: 32px;

  margin: 4px;

  position: absolute;

  display: block;

  border: 4px solid var(--white-100);
  border-color: var(--white-100) transparent transparent;
  border-radius: 50%;

  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

.ldsRing.dark div {
  border: 4px solid var(--black-100);
  border-color: var(--black-100) transparent transparent;
}

.ldsRing.centered {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

