.stores {
padding: 0;
  margin: 0 0 40px;

  display: flex;
  align-items: center;

  list-style: none;
  gap: 5px;
}

.storeLink {
  width: 146px;
  height: 43px;

  display: block;
  flex-shrink: 0;
}
