.toggle_button_group {
  display: flex;
  gap: 5px;
  border-radius: 5px;
  background-color: #e5e5e5;
  overflow: hidden;
}

.toggle_button {
  width: auto;
  padding: 9px;
  margin: 0;
  border-radius: 5px;
  border: 3px solid #e5e5e5;
  box-sizing: border-box;
  background-color: transparent;
  color: #1b1a1f;
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: #1b1a1f;
    opacity: 0.3;
  }
}

.selected {
  background-color: #ffffff;
  color: #000000;
  opacity: 1;

  &:hover {
    background-color: #ffffff;
    color: #000000;
    opacity: 1;
  }
}
