.secure {
  padding: 4px 8px;

  display: inline-flex;
  flex-shrink: 0;
  align-items: flex-start;

  color: var(--green-color);
  background: rgb(58 181 108 / 10%);

  border-radius: 5px;

  font-weight: 500;
  font-size: 10px;

  font-family: Manrope, sans-serif;
  font-style: normal;
  line-height: normal;
  gap: 4px;
}

.secureIcon {
  width: 14px;
  height: 14px;
}
