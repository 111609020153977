@import 'src/styles/mixins';

.card {
  @include flex($justify: center, $align: center);
  width: 100%;

  padding: 8px;

  background-color: var(--grey-light);

  border-radius: 8px;

  transition: box-shadow 250ms, background-color 250ms, opacity 250ms;

  cursor: pointer;

  &.is_active {
    background: var(--blue-10);

    box-shadow: 0 0 0 2px inset var(--blue-50);
  }
}
