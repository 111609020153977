@import 'src/styles/mixins';
@import 'src/styles/media-queries';

.container {
  max-width: 620px;
  margin: 0 auto;
  background: var(--white-100);

  overflow: hidden;

  border-radius: 12px 12px 0 0;
}

.header {
  position: sticky;
  z-index: 5;
  top: 0;
  padding: 20px 10px 10px;
  background: var(--white-100);

  &__title {
    font-size: 20px !important;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 3;
    width: 14px;
    height: 14px;
  }
}

.form {
  padding: 10px 30px 40px;
  position: relative;
}

.buttons {
  @include flex($justify: space-between);
  gap: 12px;
}

.order {
  position: absolute;
  top: 72px;
  left: 30px;
  right: 30px;
}

.paypal {
  padding: 0 16px;
}

.apple_google_pay {
  margin: 14px 0;
}

.divider {
  @include flex($align: center);
  position: relative;
  height: 20px;
  margin-top: 20px;
  width: 100%;

  &__text {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--black-50);
    background: var(--white-100);
    padding: 0 10px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 1px;
    background: var(--grey-light);
  }
}

.slider {
  /* stylelint-disable */
  display: flex !important;
  /* stylelint-enable */
  flex-direction: column-reverse;
  //padding: 0 12px;

  @include medium() {
    padding: unset;
  }

  &.is_disabled {
    pointer-events: none !important;
  }
}

.slide {
  width: 100%;
  /* stylelint-disable */
  height: auto !important;


  &__pay_button {
    margin-bottom: 15px;
  }
}
