@import 'src/styles/mixins';

.container {
  max-width: 620px;
  max-height: calc(100vh - 16px);
  margin: 0 auto;
}

.content {
  border-radius: 12px 12px 0 0;
  background: var(--white-100);
  padding: 16px 20px;
}

.header {
  margin-bottom: 16px;
}

.form {
  border-radius: 16px;
  padding: 0 16px;
  background: #F6F8FA;
  position: relative;
  margin-bottom: 16px;
}

.option {
  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-5);
  }

  &__header {
    @include flex($justify: space-between, $align: center);

    &_text {
      font-weight: 600 !important;
    }

    &_icon {

    }
  }

  &__content {
    padding-bottom: 16px;

    &_list {
      @include flex($direction: column);
      gap: 12px;
      margin-bottom: 16px;

      &_item {
        @include flex();
        gap: 10px;

        svg {
          flex-shrink: 0;
        }
      }
    }

    &_disclaimer {
      margin-top: 12px;
      text-align: center;
      font-size: 10px !important;
      opacity: 0.5;
    }

    &_secure_img {
      display: block;
      margin: 10px auto 0;
      max-width: calc(100% - 40px);
    }
  }
}

.order {
  margin-bottom: 20px;
}


