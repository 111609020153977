@import "src/styles/mixins";

.container {
  width: 100%;
  max-width: 500px;

  padding: 50px 20px 20px;
  margin: 0 auto;

  position: relative;

  flex: 1;

  background: var(--white-100);

  text-align: center;

  @include flex($direction: column);
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.title {
  margin: 0 0 30px;
}

.apple_google_pay {
  margin-top: 20px;
}

.divider {
  margin: 20px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--grey-dark);

  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  gap: 8px;

  &::before, &::after {
    width: 100%;
    height: 1px;

    display: block;

    opacity: 0.5;

    background: var(--grey-dark);
    content: '';
  }
}

.backdrop {
  background: rgb(12 12 12 / 50%);
}
