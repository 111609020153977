@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-weight: $weight;
    font-family: $font-family;
    font-style: $style;
    font-display: block;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'),
    url('#{$file-path}.ttf') format('truetype');
  }
}

@mixin truncate {
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin truncate-multiline($lineCount) {
  overflow: hidden;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineCount;
}

@mixin button-reset {
  padding: 0;

  display: block;

  background: none;

  border: none;
  outline: none;

  text-decoration: none;

  cursor: pointer;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

@mixin flex($type: flex, $align: false, $justify: false, $direction: false) {
  display: $type;

  @if ($align) {
    align-items: $align;
  }

  @if ($justify) {
    justify-content: $justify;
  }

  @if ($direction) {
    flex-direction: $direction;
  }
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin circle($size) {
  width: $size;
  height: $size;

  border-radius: 50%;
}

@mixin background-clippedText($background) {
  color: transparent;
  background: $background;
  background-size: cover;
  background-clip: text;
}

@mixin ignore-parents-width() {
  width: 100vw;

  margin-left: calc(50% - 50vw);
}
