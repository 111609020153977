.copyButton {
  width: 275px;

  padding: 15px 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--input-color);
  background: var(--input-bg-color);

  border: 0;
  border-radius: 8px;

  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  line-height: normal;

cursor: pointer;
  gap: 10px;
}

.icon {
  width: 15px;
  height: 15px;
}
