@import "src/styles/mixins";

.order {
  padding: 10px;

  &__plan {
    padding: 16px 0;
    margin-bottom: 16px;

    border-top: 1px solid var(--grey-ultralight);
    border-bottom: 1px solid var(--grey-ultralight);

    @include flex($direction: column);
    gap: 10px;
  }

  &__total {
    @include flex($direction: column);
    gap: 4px;
  }

  &__line {
    @include flex($align: center, $justify: space-between);
    gap: 24px;
  }

  &__text {
    color: var(--black-50);

    font-weight: 400 !important
  }

  &__text_red {
    color: var(--red-color);

    font-weight: 600 !important
  }
}
