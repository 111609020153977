@import "src/styles/mixins";

.header {
  @include flex($align: center);
  gap: 10px;
  padding: 8px 0;
  cursor: pointer;
  @include flex($align: center, $justify: space-between);

  &__radio_button {
    position: relative;
    @include circle(18px);
    flex-shrink: 0;
    box-shadow: 0 0 0 1px inset var(--black-50);
    transition: box-shadow 300ms;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include circle(8px);
      background-color: transparent;
      transition: background-color 300ms;
    }

    &.is_active {
      box-shadow: 0 0 0 1.5px inset var(--yellow-accent);

      &::after {
        background-color: var(--yellow-accent);
      }
    }
  }

  &__title {
    width: 100%;
  }
}

.content {
  overflow: hidden;
  transition: max-height 300ms ease;
}
