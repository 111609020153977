@import "src/styles/mixins";

.container {
  @include flex($align:center, $justify: center);
  padding: 10px;
  gap: 50px;
}

.store {
  display: block;
  @include flex($direction: column, $align: center);
  gap: 10px;

  &__icon {
    display: block;
    @include square(38px);
  }

  &__text {
    opacity: 0.5;
  }
}

.button {
  width: 100%;
  height: 60px;

  padding: 0 20px;

  @include flex($justify: center, $align: center);

  color: var(--white-100);
  background-color: var(--grey-dark);

  border-radius: 12px;
  outline: none;

  font-weight: 700;
  font-size: 18px;

  cursor: pointer;
}
