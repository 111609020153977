@import "src/styles/mixins";
@import "src/styles/fonts";

.container {
  background: linear-gradient(180deg, #0E0827 0%, #0B0A13 50%, #0E0E0E 100%);;
}

.logo {
  margin: 0 auto;
}

.content {
  box-sizing: border-box;
  max-width: 600px;
  min-height: 100dvh;
  max-height: 100dvh;

  margin: auto;
  padding: 16px 0 72px;
  overflow: hidden;

  display: grid;
  grid-template-rows: auto 1fr auto auto;
}

.loader {
  @include flex($direction: column, $align: center, $justify: center);
  gap: 16px;

  &__spinner {
    width: 54px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background:
      linear-gradient(0deg ,rgb(255 255 255/50%) 30%,#0000 0 70%,rgb(255 255 255/100%) 0) 50%/8% 100%,
      linear-gradient(90deg,rgb(255 255 255/25%) 30%,#0000 0 70%,rgb(255 255 255/75% ) 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: spinner 1s infinite steps(12);

    &::before,
    &::after {
      content: "";
      grid-area: 1/1;
      border-radius: 50%;
      background: inherit;
      opacity: 0.915;
      transform: rotate(30deg);
    }

    &::after {
      opacity: 0.83;
      transform: rotate(60deg);
    }
  }

  &__text {
    color: var(--white-100);
    font-family: $tertiary-font-family;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-left: 4px;
  }
}

@keyframes spinner {
  100% {transform: rotate(1turn)}
}

