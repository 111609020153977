@mixin small {
  @media screen and (width >= 518px) {
    @content;
  }
}

@mixin medium {
  @media screen and (width >= 756px) {
    @content;
  }
}

@mixin large {
  @media screen and (width >= 1152px) {
    @content;
  }
}

@mixin tiny-only {
  @media screen and (width < 518px) {
    @content;
  }
}

@mixin super-tiny {
  @media screen and (width < 360px) {
    @content;
  }
}

@mixin small-only {
  @media screen and (width >= 518px) and (width < 756px) {
    @content;
  }
}

@mixin medium-only {
  @media screen and (width >= 756px) and (width < 1152px) {
    @content;
  }
}

@mixin without-large {
  @media screen and (width < 1152px) {
    @content;
  }
}

