@import "src/styles/mixins";
@import "src/styles/fonts";

.brand {
  @include flex($direction: column, $align: center);
  padding: 32px 12px 0;

  text-align: center;

  &__logo_icon {
    margin-bottom: 8px;
  }

  &__logo_title {
    margin-bottom: 8px;

    font-size: 32px !important;

    font-family: $tertiary-font-family;
    line-height: 40px !important;
  }

  &__text {
    margin-bottom: 16px;

    color: var(--black-50);

    letter-spacing: -0.3px;
  }

  &__store {
    width: 144px;

    margin: 0 auto;
  }
}
